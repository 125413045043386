import * as React from 'react'
import SimpleReactLightbox from 'simple-react-lightbox'
import HeroArea from '../components/d&bProperties/jainit'
import { StaticImage } from 'gatsby-plugin-image'
import Seo from '../components/App/SEO'
import { siteUrl } from '../components/_utils'
import { useEffect, useState } from 'react'
import { getCountry } from '../action'
import { useScrollTracker } from 'react-scroll-tracker'
import ReactGA from 'react-ga'

const shortNumber = require('short-number')
let dollarAmount = 265450
const NewBeachFront = ({data}) => {
  const [countryISO, setCountryISO] = useState('ae')
  const [short, setShort] = useState(shortNumber(dollarAmount))
  const [symbol, setSymbol] = useState('$')
  const [downloadType, setDownloadFormType] = useState(null)
  useEffect(() => {
    getCountry({amount: dollarAmount}).then((data) => {
      if (data.short) {
        setShort(data.short)
      }
      if (data.symbol) {
        setSymbol(data.symbol)
      }
      if (data.iso) {
        setCountryISO(data.iso.toLowerCase())
      }
    })
  }, [])
  if (typeof window !== 'undefined') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useScrollTracker([10, 15, 25, 50, 75, 100], ({scrollY}) => {
      ReactGA.event({
        category: 'scroll',
        action: scrollY,
      })
    })
  }

  const titleParams = {
    title: `Bricks n Woods Real Estate | ${siteUrl}`,
    ogTitle: `Bricks n Woods Real Estate | ${siteUrl}`,
    description:
      'Where Escaping is staying | Enjoy the perks of living by a crystal lagoon in central Dubai, Caribbean-style crystal lagoon facing residences',
    ogDescription:
      'Where Escaping is staying | Enjoy the perks of living by a crystal lagoon in central Dubai, Caribbean-style crystal lagoon facing residences',
    keywords: '5 YEAR PAYMENT PLAN',
    // secureImageUrl: `${PrivatePool}`,
    // ogImage: `${PrivatePool}`,
    ogImageAlt: 'Central Park',
    orUrl: `${siteUrl}meydan-units-4-sales/`,
    pageLink: `${siteUrl}meydan-units-4-sales/`,
    canonicalRef: `${siteUrl}meydan-units-4-sales//`,
  }
  return (
    <SimpleReactLightbox>
      <Seo data={titleParams}/>
      {/*<NavHeader logoComp={logoComp}/>*/}
      <HeroArea
        countryISO={countryISO}
        onFormOpen={(v) => {
          setDownloadFormType(v)
        }}
        short={short}
        symbol={symbol}
      />
    </SimpleReactLightbox>
  )
}
export default NewBeachFront
